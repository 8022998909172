import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  TextField,
  Button,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Slider,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import { useState, useContext, useEffect } from "react";
import Dropzone from "react-dropzone";
import FlexBetween from "../../components/FlexBetween";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../../components/Header";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { styled } from "@mui/material/styles";
import CategoriesContext from "../../context";

import "./ticket.css";

const checkoutSchema = yup.object().shape({
  title: yup.string().required("Champ requis"),
  description: yup.string().required("Champ requis"),
  category: yup.string().required("Champ requis"),
  priority: yup.string().required("Champ requis"),
  progress: yup.string().required("Champ requis"),
  status: yup.string().required("Champ requis"),
  owner: yup.string().required("Champ requis"),
  avatar: yup.string(),
});

const initialValues = {
  title: "",
  description: "",
  category: "",
  priority: "1",
  progress: "0",
  status: "not started",
  owner: "Vincent LOR",
  avatar: "",
  timestamp: new Date().toISOString(),
};

var initialValuesEdit = {};

const Ticket = ({ editMode }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isSidebar, setIsSidebar] = useState(true);
  const [formData, setFormData] = useState({});
  const [isLoading, setLoading] = useState(true);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const navigate = useNavigate();
  let { id } = useParams();

  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const [shake, setShake] = useState(false);

  const animate = () => {
    // Button begins to shake
    setShake(true);

    // Buttons stops to shake after 2 seconds
    setTimeout(() => setShake(false), 1000);
  };

  const BpIcon = styled("span")(() => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    backgroundColor: "#394b59",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#30404d",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(57,75,89,.5)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: colors.greenAccent[400],
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: colors.greenAccent[500],
    },
  });

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 100,
      label: "100",
    },
  ];

  const PrettoSlider = styled(Slider)({
    color: colors.greenAccent[500],
    height: 10,
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: colors.greenAccent[500],
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&:before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  });

  const ticket = async (values, onSubmitProps) => {
    // this allows us to send form info with image
    const formDataTicket = {
      title: values.title,
      description: values.description,
      category: values.category,
      avatarPath: values.avatar.name,
      priority: values.priority,
      progress: values.progress,
      status: values.status,
      owner: values.owner,
      avatar: values.avatar,
    };

    if (!editMode) {
      const response = await axios.post("https://crm.vincentlor-dev.fr/api/tickets", {
        data: formDataTicket,
      });
      const success = response.status === 200;
      if (success) {
        navigate("/tickets");
      } else {
        setErrorMessage("Erreur lors de la validation");
        animate();
      }
    } else {
      const response = await axios.put(`https://crm.vincentlor-dev.fr/api/tickets/${id}`, {
        data: formDataTicket,
      });
      const success = response.status === 200;
      if (success) {
        navigate("/tickets");
      } else {
        setErrorMessage("Erreur lors de la validation");
        animate();
      }
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    await ticket(values, onSubmitProps);
  };

  const fetchData = async () => {
    const response = await axios
      .get(`https://crm.vincentlor-dev.fr/api/tickets/${id}`)
      .then((response) => {
        setFormData(response.data.data);
        initialValuesEdit = {
          title: response.data.data.title,
          description: response.data.data.description,
          category: response.data.data.category,
          priority: response.data.data.priority,
          progress: response.data.data.progress,
          status: response.data.data.status,
          owner: response.data.data.owner,
          avatar: response.data.data.avatar,
          avatarPath: response.data.data.avatarPath,
        };
        setLoading(false);
      });
  };

  useEffect(() => {
    if (editMode) {
      fetchData();
    }
  }, []);

  if (isLoading && editMode) {
    return <div className="app">Chargement...</div>;
  }

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Box m="20px">
          <Header
            title={editMode ? "Modifier le projet" : "Créer un projet"}
            subtitle={
              editMode
                ? "Modifier le projet existant"
                : "Créer un nouveau projet"
            }
          />

          <Formik
            onSubmit={handleFormSubmit}
            initialValues={editMode ? initialValuesEdit : initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  {errorMessage && (
                    <Typography
                      fontWeight="500"
                      fontSize="14px"
                      color="red"
                      sx={{
                        gridColumn: "span 4",
                      }}
                      className={shake ? `shake` : null}
                    >
                      {errorMessage}
                    </Typography>
                  )}
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Titre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    name="title"
                    error={!!touched.title && !!errors.title}
                    helperText={touched.title && errors.title}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="description"
                    value={values.description}
                    error={!!touched.description && !!errors.description}
                    helperText={touched.description && errors.description}
                    sx={{ gridColumn: "span 4" }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Catégorie"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="category"
                    value={values.category}
                    error={!!touched.category && !!errors.category}
                    helperText={touched.category && errors.category}
                    sx={{ gridColumn: "span 4" }}
                  />

                  <FormLabel id="priority-label" mb="2px">
                    Priorité
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="priority-label"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="priority"
                    sx={{
                      gridColumn: "span 4",
                    }}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio checkedIcon={<BpCheckedIcon />} />}
                      label="1"
                      checked={values.priority == 1}
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio checkedIcon={<BpCheckedIcon />} />}
                      label="2"
                      checked={values.priority == 2}
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio checkedIcon={<BpCheckedIcon />} />}
                      label="3"
                      checked={values.priority == 3}
                    />
                    <FormControlLabel
                      value="4"
                      control={<Radio checkedIcon={<BpCheckedIcon />} />}
                      label="4"
                      checked={values.priority == 4}
                    />
                    <FormControlLabel
                      value="5"
                      control={<Radio checkedIcon={<BpCheckedIcon />} />}
                      label="5"
                      checked={values.priority == 5}
                    />
                  </RadioGroup>

                  {editMode && (
                    <>
                      <InputLabel id="progress-label">Progression</InputLabel>
                      <PrettoSlider
                        label="Progression"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="progress"
                        sx={{ gridColumn: "span 4" }}
                        value={values.progress}
                        step={1}
                        marks={marks}
                        valueLabelDisplay="on"
                      />

                      <InputLabel id="status-label">Statut</InputLabel>
                      <Select
                        labelId="status-label"
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Statut"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name="status"
                        value={values.status}
                        sx={{ gridColumn: "span 4" }}
                      >
                        <MenuItem value="not started">Non commencé</MenuItem>
                        <MenuItem value="working on it">En cours</MenuItem>
                        <MenuItem value="stuck">Bloqué</MenuItem>
                        <MenuItem value="done">Terminé</MenuItem>
                      </Select>
                    </>
                  )}
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Créateur"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="owner"
                    value={values.owner}
                    error={!!touched.owner && !!errors.owner}
                    helperText={touched.owner && errors.owner}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <Box
                    gridColumn="span 4"
                    border={`1px solid ${colors.grey[100]}`}
                    borderRadius="5px"
                    p="1rem"
                  >
                    <Dropzone
                      acceptedFiles=".jpg,.jpeg,.png"
                      multiple={false}
                      onDrop={(acceptedFiles) =>
                        setFieldValue("avatar", acceptedFiles[0])
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <Box
                          {...getRootProps()}
                          border={`2px dashed ${colors.primary}`}
                          p="1rem"
                          sx={{ "&:hover": { cursor: "pointer" } }}
                        >
                          <input {...getInputProps()} />
                          {!values.avatar ? (
                            <p>Ajouter une photo</p>
                          ) : (
                            <FlexBetween>
                              <Typography>{values.avatar.name}</Typography>
                              <EditOutlinedIcon />
                            </FlexBetween>
                          )}
                        </Box>
                      )}
                    </Dropzone>
                  </Box>

                  <Box className="img-preview" sx={{ gridColumn: "span 4" }}>
                    {values.avatar && (
                      <img src={values.avatarPath} alt="Prévisualisation" />
                    )}
                  </Box>
                </Box>
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Valider
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </main>
    </div>
  );
};

export default Ticket;
