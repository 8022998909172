import { Navigate } from "react-router-dom";
import { useLocalState } from "../util/useLocalStorage";
import Cookies from "js-cookie";

const PrivateRoute = ({ children }) => {
  const jwt = Cookies.get('jwt');

  return jwt ? children : <Navigate to="/" />;
};

export default PrivateRoute;
