import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Box, Typography, useTheme } from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { useState } from "react";

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Box m="20px">
          <Header title="FAQ" subtitle="Foire aux questions"></Header>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                Que signifie CRM ?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                CRM signifie Customer Relationship Management en Anglais soit
                Gestion de la Relation Client (GRC). La gestion de la relation
                client est une stratégie par laquelle l'entreprise vise à
                comprendre, à anticiper et à gérer les besoins de ses clients
                actuels et potentiels. Pour en savoir plus : Centralisation et
                fidélisation Schéma des principales fonctions d'un logiciel…
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                Que signifie CXM ?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                CXM signifie Customer Experience Management en Anglais soit
                Gestion de l'expérience client. Le CXM est une approche plus
                qualitative de la relation client qui s'attache à prendre en
                compte les souhaits individuels des clients. Il vise à fournir
                une expérience personnalisée, dynamique et ciblée pour chaque
                contact client. [...]La solution CXM permet de disposer des
                informations clients en…
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                Qu'est-ce qu'un logiciel TRMS ?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                TRMS signifie Training Resource Management System en Anglais
                soit Solution de gestion de la formation. Le système de gestion
                des formations est un logiciel destiné aux professionnels de la
                formation, entreprises et organismes de formation
                professionnelle, incluant la gestion du "back-office" avec des
                fonctions logistique, financière, et administrative des
                activités de formations.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                Que signifie ITSM ?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                ITSM signifie information technology service management en
                Anglais soit la gestion des services informatiques. La gestion
                des services informatiques désigne simplement la manière dont la
                technologie de l'information (IT) est utilisée au sein d'une
                entreprise. Elle comprend l'ensemble des processus et activités
                de conception, de création, de prestation et de support des
                services…
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography color={colors.greenAccent[500]} variant="h5">
                Que signifie MRR ?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                MRR signifie Monthly Recurring Revenue soit le revenue mensuel
                récurrent Le MRR est indicateur clé pour le modèle économique
                basé sur les abonnements. Il est souvent utilisé par les
                entreprise de logiciels proposés en SaaS. Il permet de mesurer
                l'évolution et la rentabilité des ventes d'abonnements.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </main>
    </div>
  );
};

export default FAQ;
