import axios from 'axios'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const DeleteBlock = ({documentId}) => {

    const deleteTicket = async () => {
        const response = await axios.delete('https://crm.vincentlor-dev.fr/api/tickets/'+documentId)
        const success = response.status == 200

        if(success) window.location.reload()
    }
    return (
        <div className="delete-block">
            <div className="delete-icon" onClick={() => {
                if (window.confirm("Voulez-vous supprimer ce projet ?")) {
                    deleteTicket();
                }
            }}><HighlightOffIcon/></div>
        </div>
    )
}

export default DeleteBlock