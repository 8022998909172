const StatusDisplay = ({status}) => {

    const getColor = (status) => {
        let color
        switch(status) {
            case 'done' :
                color = 'rgb(186,255,201)'
                break
            case 'working on it' :
                color = 'rgb(255,223,186)'
                break
            case 'stuck' :
                color = 'rgb(255,179,186)'
                break
            default :
                color = 'rgb(186,255,255)'
        }

        return color
    }

    const getValue = (status) => {
        let value
        switch(status) {
            case 'done' :
                value = 'Terminé'
                break
            case 'working on it' :
                value = 'En cours'
                break
            case 'stuck' :
                value = 'Bloqué'
                break
            default :
            value = 'Non commencé'
        }

        return value
    }


    return (
        <div className="status-display" style={{backgroundColor:getColor(status)}}>
            {getValue(status)}
        </div>
    )
}

export default StatusDisplay