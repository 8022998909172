import { useState, useEffect, useMemo } from "react";
import FullCalendar from "@fullcalendar/react";
import { formatDate } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import frLocale from "@fullcalendar/core/locales/fr";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import axios from "axios";

const Calendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  let calendar = [];

  const [currentEvents, setCurrentEvents] = useState([]);
  const [isSidebar, setIsSidebar] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [shake, setShake] = useState(false);

  const animate = () => {
    // Button begins to shake
    setShake(true);

    // Buttons stops to shake after 2 seconds
    setTimeout(() => setShake(false), 1000);
  };



  const addCalendar = async (title, selected) => {
    const formData = {
      id: `${selected.startStr}-${title}`,
      title,
      start: selected.startStr,
      end: selected.endStr,
      allDay: selected.allDay,
    };

    const response = await axios.post(`https://crm.vincentlor-dev.fr/api/calendar`, {
      data: formData,
    });
    const success = response.status === 200;
    if (success) {
      //navigate("/contacts");
    } else {
      setErrorMessage("Erreur lors de l'enregistrement");
      animate();
    }
  };

  const deleteCalendar = async (documentId) => {
    await axios.delete("https://crm.vincentlor-dev.fr/api/calendar/" + documentId);
  };

  const handleDateClick = (selected) => {
    const title = prompt(
      "Veuillez entrer un titre pour votre nouvel évènement"
    );
    const calendarApi = selected.view.calendar;
    calendarApi.unselect();

    if (title) {
      calendarApi.addEvent({
        id: `${selected.startStr}-${title}`,
        title,
        start: selected.start,
        end: selected.endStr,
        allDay: selected.allDay,
      });
      addCalendar(title, selected);
    }
  };

  const handleEventClick = (selected) => {
    if (
      window.confirm(
        `Voulez-vous supprimer l'évènement '${selected.event.title}'?`
      )
    ) {
      selected.event.remove();
      deleteCalendar(selected.event.id);
    }
  };

  const getCalendarEvents = () => (info, successCallback, failureCallback) => {
    fetch(`https://crm.vincentlor-dev.fr/api/calendar`)
      .then((res) => res.json())
      .then((res) => {
        if (res.data) {
          calendar = [];
          const dataObject = res.data;     

          const arrayOfKeys = Object.keys(dataObject);
          const arrayOfData = Object.keys(dataObject).map(
            (key) => dataObject[key]
          );
          arrayOfKeys.forEach((key, index) => {
            const formattedData = { ...arrayOfData[index] };
            const el = {
              id: key,
              title: formattedData.title,
              start: formattedData.start,
              end: formattedData.end,
            };
            calendar.push(el);
          });
          successCallback(calendar);
        } else {
          failureCallback(new Error(res.error || "Something went wrong."));
        }
      });
  };

  const memoizedEventsFn = useMemo(() => {
    return getCalendarEvents();
  }, []);

  if (isLoading) {
    return <div className="app">Chargement...</div>;
  }

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Box m="20px">
          <Header title="CALENDRIER" subtitle="Calendrier intéractif"></Header>
          <Box display="flex" justifyContent="space-between">
            {/* Calendar SideBar */}
            <Box
              flex="1 1 20%"
              backgroundColor={colors.primary[400]}
              p="15px"
              borderRadius="4px"
            >
              {errorMessage && (
                <Typography
                  fontWeight="500"
                  fontSize="14px"
                  color="red"
                  sx={{
                    gridColumn: "span 4",
                  }}
                  className={shake ? `shake` : null}
                >
                  {errorMessage}
                </Typography>
              )}
              <Typography variant="h5">Evènements</Typography>
              <List>
                {currentEvents.map((event) => (
                  <ListItem
                    key={event.id}
                    sx={{
                      backgroundColor: colors.greenAccent[500],
                      margin: "10px 0",
                      borderRadius: "2px",
                    }}
                  >
                    <ListItemText
                      primary={event.title}
                      secondary={
                        <Typography>
                          {formatDate(event.start, {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          })}
                        </Typography>
                      }
                    ></ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>

            {/* Calendar */}
            <Box flex="1 1 100%" ml="15px">
              <FullCalendar
                locale={frLocale}
                height="75vh"
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listPlugin,
                ]}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                }}
                initialView="dayGridMonth"
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                select={handleDateClick}
                eventClick={handleEventClick}
                eventsSet={(events) => setCurrentEvents(events)}
                events={memoizedEventsFn}
              />
            </Box>
          </Box>
        </Box>
      </main>
    </div>
  );
};

/* [
                  {
                    id: "1234",
                    title: "Tous les évènements",
                    date: "2023-03-16",
                  },
                  { id: "1254", title: "Robot", date: "2023-03-14" },
                ] */

export default Calendar;
