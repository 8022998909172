import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar, frFR } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";

import { useTheme } from "@mui/material";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isSidebar, setIsSidebar] = useState(true);

  const [contacts, setContacts] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get("https://crm.vincentlor-dev.fr/api/contacts");

      const dataObject = response.data.data;

      const arrayOfKeys = Object.keys(dataObject);
      const arrayOfData = Object.keys(dataObject).map((key) => dataObject[key]);
      const formattedArray = [];
      arrayOfKeys.forEach((key, index) => {
        const formattedData = { ...arrayOfData[index] };
        formattedData["id"] = index+1;
        formattedArray.push(formattedData);
      });

      setContacts(formattedArray);
    }
    fetchData();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    { field: "registrarId", headerName: "Registre ID", flex: 0.5 },
    {
      field: "firstName",
      headerName: "Prénom",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "lastName",
      headerName: "Nom",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "age", headerName: "Age", headerAlign: "left", align: "left" },
    { field: "phone", headerName: "Téléphone", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "address", headerName: "Adresse", flex: 1 },
    { field: "city", headerName: "Ville", flex: 1 },
    { field: "zipCode", headerName: "Code postal", flex: 1 },
  ];

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Box m="20px">
          <Header
            title="CONTACTS"
            subtitle="Liste des contacts / prospects"
          ></Header>
          <Box
            m="40px 0 0 0"
            height="70vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !important`,
              },
            }}
          >
            <DataGrid
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              rows={contacts}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
            />
          </Box>
          <Box display="flex" justifyContent="end" mt="20px">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                navigate("/form");
              }}
              sx={{
                fontWeight: "bold",
              }}
            >
              Nouveau Contact
            </Button>
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default Contacts;
