import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  TextField,
  Button,
  Avatar,
} from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import Dropzone from "react-dropzone";
import FlexBetween from "../../components/FlexBetween";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import "./register.css";

const registerSchema = yup.object().shape({
  firstName: yup.string().required("Champ requis"),
  lastName: yup.string().required("Champ requis"),
  email: yup.string().email("Email non valide").required("Champ requis"),
  password: yup.string().required("Champ requis"),
  occupation: yup.string().required("Champ requis"),
  picture: yup.string(),
});

const initialValuesRegister = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  occupation: "",
  picture: "",
};

const RegisterPage = () => {
  const [errorMessage, setErrorMessage] = useState("");
  

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  //const dispatch = useDispatch();
  const navigate = useNavigate();

  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const [shake, setShake] = useState(false);

  const animate = () => {
    // Button begins to shake
    setShake(true);

    // Buttons stops to shake after 2 seconds
    setTimeout(() => setShake(false), 1000);
  };


  const register = async (values, onSubmitProps) => {
    // this allows us to send form info with image
    const formDataRegister = {
      firstName: values.firstName,
      lastName: values.lastName,
      occupation: values.occupation,
      picturePath: values.picture.name,
      email: values.email,
      password: values.password,
    };

    
    const response = await axios.post(`https://crm.vincentlor-dev.fr/api/auth/register`, {
      data: formDataRegister,
    });
    const success = response.status === 200;
    if (success) {
      navigate('/')
    } else {
      setErrorMessage("Erreur lors de l'enregistrement");
      animate();
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
    await register(values, onSubmitProps);
  };

  return (
    <div className="login-component">
      <Box>
        <Box
          width="100%"
          backgroundColor="transparent"
          p="1rem 6%"
          textAlign="center"
        >
          <Typography
            fontWeight="bold"
            fontSize="24px"
            color={colors.primary[100]}
          >
            Connexion
          </Typography>
        </Box>

        <Box
          width={isNonMobileScreens ? "40%" : "93%"}
          p="2rem"
          m="5rem auto"
          borderRadius="1.5rem"
          backgroundColor="#1F2A40DD"
        >
          <Typography
            textAlign="center"
            fontWeight="500"
            variant="h5"
            sx={{ mb: "1.5rem" }}
          >
            Bienvenue sur le CRM développé par Vincent LOR
          </Typography>

          {/* FORM START */}
          <Box width="100%" textAlign="center" m="20px auto">
            <Avatar
              sx={{ margin: "auto", backgroundColor: colors.primary[100] }}
            >
              <LockOutlinedIcon />
            </Avatar>
          </Box>

          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesRegister}
            validationSchema={registerSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  {errorMessage && (
                    <Typography
                      fontWeight="500"
                      fontSize="14px"
                      color="red"
                      sx={{
                        gridColumn: "span 4",
                      }}
                      className={shake ? `shake` : null}
                    >
                      {errorMessage}
                    </Typography>
                  )}

                  <>
                    <TextField
                      label="Prénom"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="firstName"
                      value={values.firstName}
                      error={
                        Boolean(touched.firstName) && Boolean(errors.firstName)
                      }
                      helperText={touched.firstName && errors.firstName}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      label="Nom"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="lastName"
                      value={values.lastName}
                      error={
                        Boolean(touched.lastName) && Boolean(errors.lastName)
                      }
                      helperText={touched.lastName && errors.lastName}
                      sx={{ gridColumn: "span 2" }}
                    />
                    <TextField
                      label="Fonction"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="occupation"
                      value={values.occupation}
                      error={
                        Boolean(touched.occupation) &&
                        Boolean(errors.occupation)
                      }
                      helperText={touched.occupation && errors.occupation}
                      sx={{ gridColumn: "span 4" }}
                    />
                    <Box
                      gridColumn="span 4"
                      border={`1px solid ${colors.grey[100]}`}
                      borderRadius="5px"
                      p="1rem"
                    >
                      <Dropzone
                        acceptedFiles=".jpg,.jpeg,.png"
                        multiple={false}
                        onDrop={(acceptedFiles) =>
                          setFieldValue("picture", acceptedFiles[0])
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <Box
                            {...getRootProps()}
                            border={`2px dashed ${colors.primary}`}
                            p="1rem"
                            sx={{ "&:hover": { cursor: "pointer" } }}
                          >
                            <input {...getInputProps()} />
                            {!values.picture ? (
                              <p>Ajouter une photo</p>
                            ) : (
                              <FlexBetween>
                                <Typography>{values.picture.name}</Typography>
                                <EditOutlinedIcon />
                              </FlexBetween>
                            )}
                          </Box>
                        )}
                      </Dropzone>
                    </Box>
                  </>

                  <TextField
                    label="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={Boolean(touched.email) && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    label="Mot de passe"
                    type="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={
                      Boolean(touched.password) && Boolean(errors.password)
                    }
                    helperText={touched.password && errors.password}
                    sx={{ gridColumn: "span 4" }}
                  />
                </Box>

                {/* BUTTONS */}
                <Box>
                  <Button
                    fullWidth
                    type="submit"
                    sx={{
                      m: "2rem 0",
                      p: "1rem",
                      backgroundColor: colors.primary[500],
                      color: colors.greenAccent[500],
                      "&:hover": {
                        color: colors.primary[100],
                        backgroundColor: colors.primary[400],
                      },
                    }}
                  >
                    "S'ENREGISTRER"
                  </Button>
                  <Typography
                    onClick={() => {
                      navigate("/");
                    }}
                    sx={{
                      textDecoration: "underline",
                      color: colors.primary[300],
                      "&:hover": {
                        cursor: "pointer",
                        color: colors.primary[100],
                      },
                    }}
                  >
                    Déjà un compte ? Connectez-vous ici.
                  </Typography>
                </Box>
              </form>
            )}
          </Formik>

          {/* FORM END */}
        </Box>
      </Box>
    </div>
  );
};

export default RegisterPage;
