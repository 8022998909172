import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useState } from "react";
//import Dashboard from './pages/Dashboard'
import TicketPage from "./scenes/tickets/TicketPage";
import CategoriesContext from "./context";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Contacts from "./scenes/contacts";
import Invoices from "./scenes/invoices";
import Form from "./scenes/form";
import Calendar from "./scenes/calendar";
import FAQ from "./scenes/faq";
import Bar from "./scenes/bar";
import Pie from "./scenes/pie";
import Line from "./scenes/line";
import Geography from "./scenes/geography";
import LoginPage from "./scenes/login";
import RegisterPage from "./scenes/register";
import Tickets from "./scenes/tickets";
import Ticket from "./scenes/tickets/Ticket";
import PrivateRoute from "./PrivateRoute";


function App() {
  const [theme, colorMode] = useMode();
  const [categories, setCategories] = useState(null)
  const value = { categories, setCategories }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <CategoriesContext.Provider value={value}>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/tickets" element={
              <PrivateRoute>
            <Tickets />
            </PrivateRoute>
            } />
            <Route path="/ticket/new" element={<PrivateRoute><Ticket /></PrivateRoute>} />
            <Route path="/ticket/:id" element={<PrivateRoute><Ticket editMode={true} /></PrivateRoute>} />
            <Route path="/register" element={<PrivateRoute><RegisterPage /></PrivateRoute>} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/team" element={<PrivateRoute><Team /></PrivateRoute>} />
            <Route path="/contacts" element={<PrivateRoute><Contacts /></PrivateRoute>} />
            <Route path="/invoices" element={<PrivateRoute><Invoices /></PrivateRoute>} />
            <Route path="/form" element={<PrivateRoute><Form /></PrivateRoute>} />
            <Route path="/calendar" element={<PrivateRoute><Calendar /></PrivateRoute>} />
            <Route path="/faq" element={<PrivateRoute><FAQ /></PrivateRoute>} />
            <Route path="/bar" element={<PrivateRoute><Bar /></PrivateRoute>} />
            <Route path="/pie" element={<PrivateRoute><Pie /></PrivateRoute>} />
            <Route path="/line" element={<PrivateRoute><Line /></PrivateRoute>} />
            <Route path="/geography" element={<PrivateRoute><Geography /></PrivateRoute>} />
          </Routes>
          </CategoriesContext.Provider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
