import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  TextField,
  Button,
  Avatar,
} from "@mui/material";
import { tokens } from "../../theme";
import { Formik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import Dropzone from "react-dropzone";
import FlexBetween from "../../components/FlexBetween";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";

import "./login.css";


const loginSchema = yup.object().shape({
  email: yup.string().email("Email non valide").required("Champ requis"),
  password: yup.string().required("Champ requis"),
});



const initialValuesLogin = {
  email: "",
  password: "",
};

const LoginPage = () => {
  const [pageType, setPageType] = useState("login");
  const [errorMessage, setErrorMessage] = useState("");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isNonMobile = useMediaQuery("(min-width:600px)");

  //const dispatch = useDispatch();
  const navigate = useNavigate();

  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const [shake, setShake] = useState(false);

  const animate = () => {
    // Button begins to shake
    setShake(true);

    // Buttons stops to shake after 2 seconds
    setTimeout(() => setShake(false), 1000);
  };


  const login = async (values, onSubmitProps) => {
    if (values.email === "demo@demo.fr" && values.password === "1234") {
      Cookies.set("jwt","data");
      navigate("/dashboard");
      onSubmitProps.resetForm();
      
    } else {
      setErrorMessage("Email ou mot de passe incorrect");
      animate();
    }
  };

  const handleFormSubmit = async (values, onSubmitProps) => {
   await login(values, onSubmitProps);
  };

  return (
    <div className="login-component">
      <Box>
        <Box
          width="100%"
          backgroundColor="transparent"
          p="1rem 6%"
          textAlign="center"
        >
          <Typography
            fontWeight="bold"
            fontSize="24px"
            color={colors.primary[100]}
          >
            Connexion
          </Typography>
        </Box>

        <Box
          width={isNonMobileScreens ? "30%" : "93%"}
          p="2rem"
          m="5rem auto"
          borderRadius="1.5rem"
          backgroundColor="#1F2A40DD"
        >
          <Typography
            textAlign="center"
            fontWeight="500"
            variant="h5"
            sx={{ mb: "1.5rem" }}
          >
            Bienvenue sur le CRM de démo développé par Vincent LOR
          </Typography>

          {/* FORM START */}
          <Box width="100%" textAlign="center" m="20px auto">
            <Avatar
              sx={{ margin: "auto", backgroundColor: colors.primary[100] }}
            >
              <LockOutlinedIcon />
            </Avatar>
          </Box>

          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValuesLogin }
            validationSchema={loginSchema }
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  {errorMessage && (
                    <Typography
                      fontWeight="500"
                      fontSize="14px"
                      color="red"
                      sx={{
                        gridColumn: "span 4",
                      }}
                      className={shake ? `shake` : null}
                    >
                      {errorMessage}
                    </Typography>
                  )}
                 

                  <TextField
                    label="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={Boolean(touched.email) && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    label="Mot de passe"
                    type="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    error={
                      Boolean(touched.password) && Boolean(errors.password)
                    }
                    helperText={touched.password && errors.password}
                    sx={{ gridColumn: "span 4" }}
                  />
                </Box>

                {/* BUTTONS */}
                <Box>
                  <Button
                    fullWidth
                    type="submit"
                    sx={{
                      m: "2rem 0",
                      p: "1rem",
                      backgroundColor: colors.primary[500],
                      color: colors.greenAccent[500],
                      "&:hover": {
                        color: colors.primary[100],
                        backgroundColor: colors.primary[400],
                      },
                    }}
                  >
                   SE CONNECTER
                  </Button>
                  <Typography
                    onClick={() => {
                      navigate('/register')
                    }}
                    sx={{
                      textDecoration: "underline",
                      color: colors.primary[300],
                      "&:hover": {
                        cursor: "pointer",
                        color: colors.primary[100],
                      },
                    }}
                  >                 
                     Pas de compte ? Inscrivez-vous ici.
                  </Typography>
                </Box>
              </form>
            )}
          </Formik>

          {/* FORM END */}

          <Typography
            fontWeight="300"
            fontStyle="italic"
            variant="h6"
            sx={{ mb: "1.5rem" }}
          >
            <br/> Email : demo@demo.fr <br/>
            Mot de passe :  1234 
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default LoginPage;
