import { Box, Button, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import "./form.css";

const Form = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [errorMessage, setErrorMessage] = useState("");
  const [isSidebar, setIsSidebar] = useState(true);

  const navigate = useNavigate();

  const [shake, setShake] = useState(false);

  const animate = () => {
    // Button begins to shake
    setShake(true);

    // Buttons stops to shake after 2 seconds
    setTimeout(() => setShake(false), 1000);
  };


  const handleFormSubmit = async (values) => {
    const formData ={
      firstName: values.firstName,
      lastName: values.lastName,
      age: values.age,
      address: values.address,
      email: values.email,
      phone: values.phone,
      city: values.city,
      zipCode: values.zipCode,
      registrarId: parseInt(values.registrarId),
    };

    const response = await axios.post(`https://crm.vincentlor-dev.fr/api/contacts`, {
      data: formData,
    });
    const success = response.status === 200;
    if (success) {
      navigate("/contacts");
    } else {
      setErrorMessage("Erreur lors de l'enregistrement");
      animate();
    }
  };

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Box m="20px">
          <Header title="Nouveau contact" subtitle="Créer un nouveau contact" />

          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  {errorMessage && (
                    <Typography
                      fontWeight="500"
                      fontSize="14px"
                      color="red"
                      sx={{
                        gridColumn: "span 4",
                      }}
                      className={shake ? `shake` : null}
                    >
                      {errorMessage}
                    </Typography>
                  )}
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Prénom"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    name="firstName"
                    error={!!touched.firstName && !!errors.firstName}
                    helperText={touched.firstName && errors.firstName}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Nom"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lastName}
                    name="lastName"
                    error={!!touched.lastName && !!errors.lastName}
                    helperText={touched.lastName && errors.lastName}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={!!touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Age"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.age}
                    name="age"
                    error={!!touched.age && !!errors.age}
                    helperText={touched.age && errors.age}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Téléphone"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    name="phone"
                    error={!!touched.phone && !!errors.phone}
                    helperText={touched.phone && errors.phone}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Adresse"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.address}
                    name="address"
                    error={!!touched.address && !!errors.address}
                    helperText={touched.address && errors.address}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Code postal"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.zipCode}
                    name="zipCode"
                    error={!!touched.zipCode && !!errors.zipCode}
                    helperText={touched.zipCode && errors.zipCode}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Ville"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.city}
                    name="city"
                    error={!!touched.city && !!errors.city}
                    helperText={touched.city && errors.city}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="ID Registre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.registrarId}
                    name="registrarId"
                    error={!!touched.registrarId && !!errors.registrarId}
                    helperText={touched.registrarId && errors.registrarId}
                    sx={{ gridColumn: "span 4" }}
                  />
                </Box>
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Ajouter
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </main>
    </div>
  );
};

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("Champ requis"),
  lastName: yup.string().required("Champ requis"),
  age: yup.string().required("Champ requis"),
  phone: yup.string().required("Champ requis"),
  email: yup.string().email("Email non valide").required("Champ requis"),
  address: yup.string().required("Champ requis"),
  city: yup.string().required("Champ requis"),
  zipCode: yup.string(),
  registrarId: yup.number().required("Champ requis").positive().integer(),
});
const initialValues = {
  'firstName': "",
  'lastName': "",
  'age': "",
  'phone': "",
  'email': "",
  'address': "",
  'city': "",
  'zipCode': "",
};

export default Form;
