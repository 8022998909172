import TicketCard from "../../components/TicketCard";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import CategoriesContext from "../../context";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { Box, Button } from "@mui/material";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";

const Tickets = () => {
  const [tickets, setTickets] = useState(null);
  const { categories, setCategories } = useContext(CategoriesContext);

  const [isSidebar, setIsSidebar] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get("https://crm.vincentlor-dev.fr/api/tickets");

      const dataObject = response.data.data;

      const arrayOfKeys = Object.keys(dataObject);
      const arrayOfData = Object.keys(dataObject).map((key) => dataObject[key]);
      const formattedArray = [];
      arrayOfKeys.forEach((key, index) => {
        const formattedData = { ...arrayOfData[index] };
        formattedData["documentId"] = key;
        formattedArray.push(formattedData);
      });

      setTickets(formattedArray);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setCategories([...new Set(tickets?.map(({ category }) => category))]);
  }, [tickets]);

  const colors = [
    "rgb(255,179,186)",
    "rgb(255,223,186)",
    "rgb(255,255,186)",
    "rgb(186,255,201)",
    "rgb(186,255,255)",
  ];

  const uniqueCategories = [
    ...new Set(tickets?.map(({ category }) => category)),
  ];

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Box m="20px">
          <Header title="Projets" subtitle="Liste des projets"></Header>
          <Box display="flex" justifyContent="end" mb="20px">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                navigate("/ticket/new");
              }}
              sx= {{
                fontWeight:"bold"
              }}
            >
              Nouveau Projet
            </Button>
          </Box>
          <div className="ticket-container">
            {tickets &&
              uniqueCategories?.map((uniqueCategory, categoryIndex) => (
                <div key={categoryIndex}>
                  <h3>{uniqueCategory}</h3>
                  {tickets
                    .filter((ticket) => ticket.category === uniqueCategory)
                    .map((filteredTicket, _index) => (
                      <TicketCard
                        id={_index}
                        color={colors[categoryIndex] || colors[0]}
                        ticket={filteredTicket}
                      />
                    ))}
                </div>
              ))}
          </div>
        </Box>
      </main>
    </div>
  );
};

export default Tickets;
