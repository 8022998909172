import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, frFR } from "@mui/x-data-grid";
import {  tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlined from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlined from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlined from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import { useState } from "react";

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isSidebar, setIsSidebar] = useState(true);

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Nom",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    { field: "age", headerName: "Age", headerAlign: "left", align: "left" },
    { field: "phone", headerName: "Téléphone", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "access",
      headerName: "Niveau d'accès",
      flex: 1,
      renderCell: ({ row: { access } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              access === "admin"
                ? colors.greenAccent[600]
                : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {access === "admin" && <AdminPanelSettingsOutlined float="left"/>} {access === "admin" && <Typography>Admin</Typography>}
            {access === "manager" && <SecurityOutlined />} {access === "manager" && <Typography>Manager</Typography>}
            {access === "user" && <LockOpenOutlined /> }  {access === "user" && <Typography>Utilisateur</Typography> }
            <Typography
              color={colors.grey[100]}
              sx={{ ml: "5px" }}
            ></Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Box m="20px">
          <Header title="EQUIPE" subtitle="Gérer mon équipe"></Header>
          <Box
            m="40px 0 0 0"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.blueAccent[700],
                borderBottom: "none",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[400],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: colors.blueAccent[700],
              },
            }}
          >
            <DataGrid
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              rows={mockDataTeam}
              columns={columns}
            />
          </Box>
        </Box>
      </main>
    </div>
  );
};

export default Team;
