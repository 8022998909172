import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import CategoriesContext from "../../context";
import Sidebar from "../global/Sidebar";
import Topbar from "../global/Topbar";
import {
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  Input,
  Autocomplete,
  useTheme,
} from "@mui/material";
import Dropzone from "react-dropzone";
import Header from "../../components/Header";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Formik } from "formik";
import * as yup from "yup";
import { tokens } from "../../theme";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FlexBetween from "../../components/FlexBetween";
import { styled } from "@mui/material/styles";

const checkoutSchema = yup.object().shape({
  title: yup.string().required("Champ requis"),
  description: yup.string().required("Champ requis"),
  category: yup.string().required("Champ requis"),
  priority: yup.string().required("Champ requis"),
  progress: yup.string().required("Champ requis"),
  status: yup.string().required("Champ requis"),
  owner: yup.string().required("Champ requis"),
  avatar: yup.string(),
});
const initialValues = {
  title: "",
  description: "",
  category: "",
  priority: "1",
  progress: 0,
  status: "not started",
  owner: "Vincent LOR",
  avatar: "",
};

const TicketPage = ({ editMode }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { categories, setCategories } = useContext(CategoriesContext);
  const [isSidebar, setIsSidebar] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [formData, setFormData] = useState({
    status: "not started",
    progress: 0,
    owner: "Vincent LOR",
    timestamp: new Date().toISOString(),
  });

  const [shake, setShake] = useState(false);

  const animate = () => {
    // Button begins to shake
    setShake(true);

    // Buttons stops to shake after 2 seconds
    setTimeout(() => setShake(false), 1000);
  };

  const navigate = useNavigate();
  let { id } = useParams();

  const BpIcon = styled("span")(() => ({
    borderRadius: "50%",
    width: 16,
    height: 16,
    backgroundColor: "#394b59",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))",
    ".Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#30404d",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(57,75,89,.5)",
    },
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: colors.greenAccent[400],
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: colors.greenAccent[500],
    },
  });

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 100,
      label: "100",
    },
  ];

  const PrettoSlider = styled(Slider)({
    color: colors.greenAccent[500],
    height: 10,
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    "& .MuiSlider-valueLabel": {
      lineHeight: 1.2,
      fontSize: 12,
      background: "unset",
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: "50% 50% 50% 0",
      backgroundColor: colors.greenAccent[500],
      transformOrigin: "bottom left",
      transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
      "&:before": { display: "none" },
      "&.MuiSlider-valueLabelOpen": {
        transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
      },
      "& > *": {
        transform: "rotate(45deg)",
      },
    },
  });

  const handleFormSubmit = async (formData) => {
    setFormData(() => ({
      title: formData.title,
      description: formData.description,
      category: formData.category,
      priority: formData.priority,
      progress: formData.progress,
      status: formData.status,
      owner: formData.owner,
      avatar: formData.avatar,
      avatarPath: formData.avatar.name,
    }));

    if (!editMode) {
      const response = await axios.post("https://crm.vincentlor-dev.fr/api/tickets", {
        formData,
      });
      const success = response.status === 200;
      if (success) {
        navigate("/tickets");
      }else {
        setErrorMessage("Erreur lors de la validation");
        animate();
      }
    } else {
      const response = await axios.put(`https://crm.vincentlor-dev.fr/api/tickets/${id}`, {
        data: formData,
      });
      const success = response.status === 200;
      if (success) {
        navigate("/tickets");
      }else {
        setErrorMessage("Erreur lors de la validation");
        animate();
      }
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeForm = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    formData[name] = value;
  };

  const fetchData = async () => {
    const response = await axios.get(`https://crm.vincentlor-dev.fr/api/tickets/${id}`);
    setFormData(response.data.data);
  };

  useEffect(() => {
    if (editMode) {
      fetchData();
    }
  }, []);

  return (
    <div className="app">
      <Sidebar isSidebar={isSidebar} />
      <main className="content">
        <Topbar setIsSidebar={setIsSidebar} />
        <Box m="20px" className="ticket">
          <Header
            title={editMode ? "Modifier le projet" : "Créer un projet"}
            subtitle={
              editMode
                ? "Modifier le projet existant"
                : "Créer un nouveau projet"
            }
          ></Header>
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChangeForm,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 4",
                    },
                  }}
                >
                  {errorMessage && (
                    <Typography
                      fontWeight="500"
                      fontSize="14px"
                      color="red"
                      sx={{
                        gridColumn: "span 4",
                      }}
                      className={shake ? `shake` : null}
                    >
                      {errorMessage}
                    </Typography>
                  )}
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Titre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={formData.title}
                    name="title"
                    error={!!touched.title && !!errors.title}
                    helperText={touched.firsttitleName && errors.title}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={formData.description}
                    name="description"
                    error={!!touched.description && !!errors.description}
                    helperText={touched.description && errors.description}
                    sx={{ gridColumn: "span 4" }}
                  />
                  {/*       <InputLabel id="categorie-label">Catégorie</InputLabel>
                  <Select
                    labelId="categorie-label"
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Catégorie"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={formData.category}
                    name="category"
                    sx={{ gridColumn: "span 4" }}
                  >
                    {categories?.map((category, _index) => (
                      <MenuItem
                        value={category}
                        key={_index}
                        selected={formData.category === "New Cat"}
                      >
                        {category}
                      </MenuItem>
                    ))}
                  </Select> */}

                  {/*         <Autocomplete
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Catégorie"
                    onBlur={handleBlur}
                    onChange={handleChange}                    
                    sx={{ gridColumn: "span 4" }}
                    disablePortal
                    id="combo-box-demo"
                    getOptionLabel={(option) => option.label}
                    options={categories?.map((category, _index) => ({
                      label: category
                    }))}
                    renderInput={(params) => (
                      <TextField {...params} variant="filled" label="Catégorie" defaultValue={formData.category} value={formData.category}    name="category" />
                    )}
                  /> */}

                  <label>Category</label>
                  <select
                    className="ticket-select"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                  >
                    {categories?.map((category, _index) => (
                      <option key={_index} value={category}>
                        {category}
                      </option>
                    ))}
                  </select>

                  <label htmlFor="new-category">New category</label>
                  <input
                    className="ticket-select"
                    id="new-category"
                    name="category"
                    type="text"
                    onChange={handleChange}
                    required={true}
                    value={formData.category}
                  />

                  <FormLabel id="priority-label">Priorité</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="priority-label"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="priority"
                    sx={{
                      gridColumn: "span 4",
                    }}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio checkedIcon={<BpCheckedIcon />} />}
                      label="1"
                      checked={formData.priority == 1}
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio checkedIcon={<BpCheckedIcon />} />}
                      label="2"
                      checked={formData.priority == 2}
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio checkedIcon={<BpCheckedIcon />} />}
                      label="3"
                      checked={formData.priority == 3}
                    />
                    <FormControlLabel
                      value="4"
                      control={<Radio checkedIcon={<BpCheckedIcon />} />}
                      label="4"
                      checked={formData.priority == 4}
                    />
                    <FormControlLabel
                      value="5"
                      control={<Radio checkedIcon={<BpCheckedIcon />} />}
                      label="5"
                      checked={formData.priority == 5}
                    />
                  </RadioGroup>

                  <InputLabel id="progress-label">Progression</InputLabel>
                  <PrettoSlider
                    label="Progression"
                    onBlur={handleBlur}
                    onChange={handleChangeForm}
                    name="progress"
                    sx={{ gridColumn: "span 4" }}
                    step={1}
                    marks={marks}
                    valueLabelDisplay="on"
                  />

                  {editMode && (
                    <>
                      <InputLabel id="status-label">Statut</InputLabel>
                      <Select
                        labelId="status-label"
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Statut"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={formData.status}
                        name="status"
                        sx={{ gridColumn: "span 4" }}
                      >
                        <MenuItem value="done">Terminé</MenuItem>
                        <MenuItem value="working on it">En cours</MenuItem>
                        <MenuItem value="stuck">Bloqué</MenuItem>
                        <MenuItem value="not started">Non commencé</MenuItem>
                      </Select>
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Créateur"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={formData.owner}
                        name="owner"
                        error={!!touched.owner && !!errors.owner}
                        helperText={touched.owner && errors.owner}
                        sx={{ gridColumn: "span 4" }}
                      />
                    </>
                  )}
                  <Dropzone
                    acceptedFiles=".jpg,.jpeg,.png"
                    multiple={false}
                    onDrop={(acceptedFiles) =>
                      setFieldValue("avatar", acceptedFiles[0])
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <Box
                        {...getRootProps()}
                        border={`2px dashed ${colors.primary}`}
                        p="1rem"
                        sx={{
                          gridColumn: "span 4",
                          "&:hover": { cursor: "pointer" },
                        }}
                      >
                        <input {...getInputProps()} />
                        {!formData.avatar ? (
                          <p>Ajouter une photo</p>
                        ) : (
                          <FlexBetween>
                            <Typography>{formData.avatar.name}</Typography>
                            <EditOutlinedIcon />
                          </FlexBetween>
                        )}
                      </Box>
                    )}
                  </Dropzone>

                  <Box className="img-preview" sx={{ gridColumn: "span 4" }}>
                    {formData.avatar && (
                      <img src={formData.avatar} alt="Prévisualisation" />
                    )}
                  </Box>
                </Box>
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    Valider
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          {/*  <form onSubmit={handleSubmit}>
                    <section>
                        <label htmlFor="title">Title</label>
                        <input 
                            id="title"
                            name="title"
                            type="text"
                            onChange={handleChange}
                            required={true}
                            value={formData.title}
                        />

                        <label htmlFor="description">Description</label>
                        <input 
                            id="description"
                            name="description"
                            type="text"
                            onChange={handleChange}
                            required={true}
                            value={formData.description}
                        />

                        <label>Category</label>
                        <select
                            name="category"
                            value={formData.category}
                            onChange={handleChange}
                            >
                                {categories?.map((category, _index) => (
                                    <option key={_index} value={category}>{category}</option>
                                ))}
                        </select>

                        <label htmlFor="new-category">New category</label>
                        <input 
                            id="new-category"
                            name="category"
                            type="text"
                            onChange={handleChange}
                            required={true}
                            value={formData.category}
                        />

                        <label>Priority</label>
                        <div className='multiple-input-container'>
                            <input
                                id="priority-1"
                                name="priority"
                                type="radio"
                                onChange={handleChange}
                                value={1}
                                checked={formData.priority == 1}
                            />
                            <label htmlFor='priority-1'>1</label>
                            <input
                                id="priority-2"
                                name="priority"
                                type="radio"
                                onChange={handleChange}
                                value={2}
                                checked={formData.priority == 2}
                            />
                            <label htmlFor='priority-2'>2</label>
                            <input
                                id="priority-3"
                                name="priority"
                                type="radio"
                                onChange={handleChange}
                                value={3}
                                checked={formData.priority == 3}
                            />
                            <label htmlFor='priority-3'>3</label>
                            <input
                                id="priority-4"
                                name="priority"
                                type="radio"
                                onChange={handleChange}
                                value={4}
                                checked={formData.priority == 4}
                            />
                            <label htmlFor='priority-4'>4</label>
                            <input
                                id="priority-5"
                                name="priority"
                                type="radio"
                                onChange={handleChange}
                                value={5}
                                checked={formData.priority == 5}
                            />
                            <label htmlFor='priority-5'>5</label>
                        </div>

                        {editMode && 
                        <>
                        <input 
                            type="range"
                            id='progress'
                            name='progress'
                            value={formData.progress}
                            min="0"
                            max="0"
                            onChange={handleChange}
                        
                        />
                        <label htmlFor='progress'>Progress</label>                

                        <label>Status</label>
                        <select
                        name="status"
                        value={formData.status}
                        onChange={handleChange}>
                            <option selected={formData.status === 'done'} value="done">Done</option>
                            <option selected={formData.status === 'working on it'} value="working on it">Working on it</option>
                            <option selected={formData.status === 'stuck'} value="stuck">Stuck</option>
                            <option selected={formData.status === 'not started'} value="not started">Not started</option>
                        </select>
                        </>
                       }

                       <input type="submit"/>
                    </section>

                    <section>
                        <label htmlFor='owner'>Propriétaire</label>
                        <input 
                            id="owner"
                            name="owner"
                            type="text"
                            onChange={handleChange}
                            required={true}
                            value={formData.owner}
                        />

                        <label htmlFor='avatar'>Avatar</label>
                        <input 
                            id="avatar"
                            name="avatar"
                            type="url"
                            onChange={handleChange}
                            required={true}
                            value={formData.avatar}
                        />

                        <div className='img-preview'>
                            {formData.avatar && (
                                <img src={formData.avatar} alt="Prévisualisation"/>
                            )}
                        </div>
                    </section>
                </form> */}
        </Box>
      </main>
    </div>
  );
};

export default TicketPage;
